import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { TfiTarget } from "react-icons/tfi";
import { toast } from "sonner";
import { pinIcon } from "../Images";
import { FaLocationArrow } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { getAutoFillLocations } from "../features/location/LocationSlice";

const LocationInputPopup = ({ handlePopup }) => {
  const dispatch = useDispatch();
  // Locataion
  const [locationText, setLocationText] = useState("");

  const [latLon, setLatLon] = React.useState({
    lat: null,
    lon: null,
  });

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatLon({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
        },
        (error) => {
          toast.warning("Please allow location");
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleLocationTextChange = (e) => {
    const value = e?.target?.value;
    setLocationText(value);

    if (value?.length >= 3) {
      dispatch(getAutoFillLocations(value));
    }
  };

  return (
    <div className="w-full h-full top-0 left-0 fixed z-[100] flex items-center justify-center ">
      <div
        className="w-full h-full bg-[#00000058] absolute "
        onClick={handlePopup}></div>
      <div className="w-[28rem] m-auto h-[18rem] bg-white rounded-xl relative max-md:absolute max-md:bottom-0 max-md:w-full popup px-10 py-16 flex flex-col items-center justify-between">
        <div
          className="absolute -top-10 -right-1 bg-white p-2 rounded-full cursor-pointer max-md:hidden"
          onClick={handlePopup}>
          <RxCross2 />
        </div>
        <div className="w-11/12 mx-auto flex gap-2 items-center border-b pb-2">
          <FaLocationArrow className="text-3xl text-primary-70 " />
          <input
            type="text"
            onChange={handleLocationTextChange}
            value={locationText}
            className="outline-none  w-full px-2"
            placeholder="Search for area, street name.."
          />
        </div>
        <button
          className=" flex items-center gap-4 border rounded p-3 w-full justify-center font-semibold tracking-wider uppercase border-[#00000049] "
          onClick={getCurrentLocation}>
          <TfiTarget className="text-2xl" />
          get currect location
        </button>
      </div>
    </div>
  );
};

export default LocationInputPopup;
