import React, { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SlLocationPin } from "react-icons/sl";
import { BsFillKeyFill, BsSearch } from "react-icons/bs";
import "../style/nav.css";
import { FaBox, FaCaretDown, FaRegUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { PiUserLight } from "react-icons/pi";
import { CiLocationOn, CiUser } from "react-icons/ci";
import { FaLocationArrow, FaRegAddressBook } from "react-icons/fa6";
import { MdHomeRepairService, MdLogin, MdLogout } from "react-icons/md";
import { BsBoxSeam } from "react-icons/bs";
import { BiSupport } from "react-icons/bi";
import { IoHomeOutline, IoLocation } from "react-icons/io5";
import { clearStore, logoutUser } from "../features/user/UserSlice";
import Confirm from "./Notifications/Confirm";
import { empluxIcon, logoEmplux, menuIcon } from "../utils/Urls";
import { getUserFromLocalStorage } from "../utils/localStorage";
import { AccountIcon2, pinIcon } from "../Images";
import LocationInputPopup from "./LocationInputPopup";
import {
  checkMyLocation,
  getAddressFromLatLon,
} from "../features/location/LocationSlice";
import { toast } from "sonner";

const Navigation = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = getUserFromLocalStorage();
  const path = location?.pathname;
  var section = path?.includes("rental") ? 1 : path?.includes("buy") ? 3 : 2;

  //states
  const [isFocus, setFocus] = useState(null);
  const [comfirming, setConfirming] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isAccount, setAccount] = useState(false);
  const [isLocationClicked, setLocationClicked] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isAskingLocation, setAskingLocation] = useState(false);

  // Store
  const { userLocation } = useSelector((state) => state.location);

  const hash = location?.hash?.slice(1);

  // Locataion

  const [latLon, setLatLon] = React.useState({
    latitude: null,
    longitude: null,
  });

  React.useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const resp = await dispatch(
            getAddressFromLatLon({
              lat: position?.coords?.latitude,
              lon: position?.coords?.longitude,
            })
          );

          await dispatch(checkMyLocation({ location: resp?.payload }));
        },
        (error) => {
          console.error("Error getting location. Please refresh.");
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  // const { profile_image_url } = useSelector((state) => state.profile.userData);
  const handleAccountHover = (el) => {
    setAccount(el);
  };
  const handleLogout = async (status) => {
    if (comfirming === "logout") {
      setConfirming(null);
    } else {
      setConfirming("logout");
    }
    if (status) {
      await dispatch(clearStore("Logged Out Successfully!"));
      navigate("/login");
    }
  };

  const handleSearchChange = (e) => {
    const val = e?.target?.value?.trim();

    if (val?.length > 1) {
      navigate("/search", { state: { value: val } });
      setSearchText("");
    } else setSearchText(val);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.pageYOffset;
      setScrollPosition(currentPosition);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <div
      className={hash === "blockBack" ? "hidden" : " sticky top-0 z-20 w-full"}>
      {/* popups */}
      {isAskingLocation && (
        <LocationInputPopup handlePopup={() => setAskingLocation(false)} />
      )}
      {comfirming === "logout" && (
        <Confirm
          question={"Are you sure you want to Log out?"}
          handleConfirmation={handleLogout}
        />
      )}
      <nav
        className="bg-white "
        style={
          scrollPosition > 10
            ? { boxShadow: "1px 0px 20px 5px rgb(0,0,0,0.05)" }
            : {}
        }>
        <div className={" mx-auto px-4 sm:px-6 lg:px-16 "}>
          <div className="flex items-center w-full  py-4 min-h-max justify-between max-md:py-2">
            {/* logo */}
            <Link
              to={"/"}
              className=" max-md:hidden  text-center flex items-center gap-2 ">
              <img
                src={empluxIcon}
                alt=""
                className="w-10 object-fill m-auto "
              />

              <span className="uppercase text-primary-600 font-semibold text-xl">
                EMPLUX
              </span>
            </Link>
            <div className="flex items-center justify-between  w-full max-md:hidden">
              {/* bar */}
              <div className="flex h-full justify-end w-full px-8 items-center ">
                {/* <div className="flex w-[20rem]  h-[2.5rem] rounded overflow-hidden uppercase text-sm max-[1300px]:w-[18rem] max-lg:w-[10rem]  ">
                  <Link
                    to={"./rental"}
                    className={
                      "flex justify-center items-center w-1/3 " +
                      (section === 1
                        ? "bg-primary-600 text-white"
                        : "  bg-[#ECECEC] text-[#676565]")
                    }>
                    <span className="max-lg:hidden">Rental</span>

                    <BsFillKeyFill className="h-5 w-5 lg:hidden" />
                  </Link>
                  <Link
                    to={"/"}
                    className={
                      "flex justify-center items-center border-x-2 w-1/3 " +
                      (section === 2
                        ? "bg-primary-600 text-white"
                        : "  bg-[#ECECEC] text-[#676565]")
                    }>
                    <span className="max-lg:hidden">Service</span>
                    <MdHomeRepairService className="h-5 w-5 lg:hidden" />
                  </Link>
                  <div
                    className={
                      "flex justify-center items-center w-1/3 cursor-pointer " +
                      (section === 3
                        ? "bg-primary-600 text-white"
                        : "  bg-[#ECECEC] text-[#676565] ")
                    }>
                    <span className="max-lg:hidden">BUY</span>
                    <FaBox className="h-5 w-5 lg:hidden" />
                  </div>
                </div> */}

                <div className="flex items-center justify-start  h-full  ">
                  <div
                    className="mr-4 text-neutral-500 flex items-center gap-2 cursor-pointer whitespace-nowrap relative"
                    onClick={() => setAskingLocation(true)}>
                    <img src={pinIcon} alt="" className="w-4" />
                    <div className="font-medium text-sm  w-[4rem] inline-block whitespace-nowrap max-xl:hidden">
                      Deliver to:
                    </div>
                    <div className="text-sm  max-w-[8rem] overflow-hidden inline-block whitespace-nowrap overflow-ellipsis  ">
                      {userLocation?.address?.address +
                        ", " +
                        userLocation?.address?.city}
                    </div>
                    <FaCaretDown className="text-primary-600" />
                  </div>
                  <div
                    className={
                      "items-center justify-center  overflow-hidden  flex-1 rounded-full py-1 pl-2 pr-1 " +
                      (isFocus ? " bg-white  border" : " bg-gray-50  border") +
                      (path === "/search" ? " hidden" : " flex")
                    }>
                    <input
                      type="text"
                      placeholder="Search electronic devices"
                      className={
                        "h-[1.8rem] px-2   text-sm min-w-[12vw] flex-1   outline-none" +
                        (isFocus ? " bg-white" : " bg-gray-50")
                      }
                      onChange={handleSearchChange}
                      value={searchText}
                      onFocus={() => setFocus(true)}
                      onBlur={() => setFocus(false)}
                    />
                    <button className=" bg-primary-600 flex items-center gap-2 text-white px-3 rounded-full text-sm py-1">
                      <BsSearch className="text-xs" />
                      <span>Search</span>
                    </button>
                  </div>
                </div>
              </div>

              <div
                className="relative hidden md:block"
                onMouseLeave={() => handleAccountHover(false)}>
                <div className=" ">
                  <div
                    className={
                      isAccount
                        ? "   flex items-center float-right bg-primary-600 rounded-full w-max justify-center cursor-pointer min-h-[2.5rem]  border   px-4   border-gray-400 "
                        : "   flex items-center float-right bg-primary-600 rounded-full w-max justify-center cursor-pointer min-h-[2.5rem]  border  border-white   px-4   "
                    }
                    onMouseEnter={() => handleAccountHover(true)}>
                    <img
                      src={AccountIcon2}
                      className="h-6 aspect-square p-1 text-primary-600 gap-2 "
                    />
                    {/* <h1 className="text-[16px] text-gray-600 font-medium ">
                        Account
                      </h1> */}
                    {/* <FiChevronDown
                        className={
                          isAccount ? "mt-1 ml-1 rotate-180" : "mt-1 ml-1"
                        }
                      /> */}
                    <span className="text-sm font-medium tracking-wider max-lg:hidden text-white ">
                      Account
                    </span>
                  </div>
                </div>

                {isAccount && (
                  <div
                    className="absolute z-10  rounded-md h-max w-[12rem] bg-white  flex flex-col min-w-max  expand-horizontal top-[100%] right-0 overflow-hidden"
                    style={{
                      boxShadow: "2px 2px 15px 0px rgb(0,0,0,0.1)",
                    }}
                    onMouseEnter={() => handleAccountHover(true)}>
                    {/* <span
                      className="absolute bottom-[100%] text-white drop-shadow-md z-50"
                      style={{ fontSize: "100px" }}>
                      &#9650;
                    </span> */}

                    <Link
                      to="/profile"
                      className="flex items-center px-3 py-2 justify-between hover:bg-gray-100 rounded-md">
                      <FaRegUser className="h-[20px] w-[20px] text-primary-600  " />
                      <h1 className="w-4/5  min-w-max">My Profile</h1>
                    </Link>
                    <Link
                      to="/my-bookings"
                      className="flex items-center px-3 py-2 justify-between hover:bg-gray-100">
                      <BsBoxSeam className="h-[20px] w-[20px] text-primary-600  " />
                      <h1 className="w-4/5  min-w-max">Bookings</h1>
                    </Link>
                    <Link
                      to="/address"
                      className="flex items-center px-3 py-2 justify-between hover:bg-gray-100">
                      <FaRegAddressBook className="h-[20px] w-[20px] text-primary-600" />
                      <h1 className="w-4/5  min-w-max">Addresses</h1>
                    </Link>
                    <Link
                      to="./help"
                      className="flex items-center px-3 py-2 justify-between hover:bg-gray-100">
                      <BiSupport className="h-[20px] w-[20px] text-primary-600" />
                      <h1 className="w-4/5  min-w-max">Help</h1>
                    </Link>
                    <>
                      {user?.userId ? (
                        ""
                      ) : (
                        <Link
                          to="/login"
                          className="flex items-center px-3 py-2 justify-between hover:bg-gray-100">
                          <MdLogin className="h-[20px] w-[20px] text-primary-600" />
                          <h1 className="w-4/5  min-w-max">Login</h1>
                        </Link>
                      )}
                    </>
                  </div>
                )}
              </div>
            </div>
            {/* ++++++++++++++++++++++++++++++++ MOBILE +++++++++++++++++++++++++++++++++++++++++++ */}
            <div className=" flex md:hidden w-full gap-2 items-center ">
              {/* search */}
              <div
                className=" px-2rounded-xl flex items-center justify-center "
                onClick={() => setAskingLocation(true)}>
                <FaLocationArrow className="text-[#489DB1] text-3xl" />
              </div>
              <div
                className={
                  "items-center justify-center  overflow-hidden flex bg-white rounded-lg  w-full h-[2.5rem] border "
                }>
                <div
                  className={
                    " text-[#676565] px-2 " +
                    (isFocus ? " text-lg" : " text-xl")
                  }>
                  <BsSearch />
                </div>
                <input
                  type="search"
                  placeholder="Search electronic devices"
                  className={
                    "h-full px-3   text-sm w-full   outline-none bg-white"
                  }
                  onChange={handleSearchChange}
                  value={searchText}
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                />
              </div>
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className=" inline-flex items-center justify-center  text-gray-400 hover:text-white focus:outline-none h-[2.75rem]   "
                aria-controls="mobile-menu"
                aria-expanded="false">
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <img
                    src={menuIcon}
                    alt=""
                    className="h-[2.75rem] w-[3.5rem] object-fill"
                  />
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
          className={"absolute shadow-lg bg-white w-full"}>
          {(ref) => (
            <div className="md:hidden" id="mobile-menu">
              <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <Link
                  to="/"
                  className="flex items-center px-3 py-2 justify-between hover:bg-gray-100 rounded-md">
                  <IoHomeOutline className="h-[20px] w-[20px] text-primary-600  " />
                  <h1 className="w-4/5  min-w-max">Home</h1>
                </Link>
                <Link
                  to="/profile"
                  className="flex items-center px-3 py-2 justify-between hover:bg-gray-100 rounded-md">
                  <FaRegUser className="h-[20px] w-[20px] text-primary-600  " />
                  <h1 className="w-4/5  min-w-max">My Profile</h1>
                </Link>
                <Link
                  to="/my-bookings"
                  className="flex items-center px-3 py-2 justify-between hover:bg-gray-100">
                  <BsBoxSeam className="h-[20px] w-[20px] text-primary-600  " />
                  <h1 className="w-4/5  min-w-max">Bookings</h1>
                </Link>
                <Link
                  to="/address"
                  className="flex items-center px-3 py-2 justify-between hover:bg-gray-100">
                  <FaRegAddressBook className="h-[20px] w-[20px] text-primary-600" />
                  <h1 className="w-4/5  min-w-max">Addresses</h1>
                </Link>
                <Link
                  to="/help"
                  className="flex items-center px-3 py-2 justify-between hover:bg-gray-100">
                  <BiSupport className="h-[20px] w-[20px] text-primary-600" />
                  <h1 className="w-4/5  min-w-max">Help</h1>
                </Link>
                <>
                  {user?.userId ? (
                    <div
                      className="flex items-center px-3 py-2 justify-between hover:bg-gray-100 cursor-pointer rounded-md"
                      onClick={() => handleLogout()}>
                      <MdLogout className="h-[20px] w-[20px] text-primary-600  " />
                      <h1 className="w-4/5  min-w-max">Log Out</h1>
                    </div>
                  ) : (
                    <Link
                      to="/login"
                      className="flex items-center px-3 py-2 justify-between hover:bg-gray-100">
                      <MdLogin className="h-[20px] w-[20px] text-primary-600" />
                      <h1 className="w-4/5  min-w-max">Login</h1>
                    </Link>
                  )}
                </>
              </div>
            </div>
          )}
        </Transition>
      </nav>
    </div>
  );
};

export default Navigation;
