import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addUserToLocalStorage,
  getUserFromLocalStorage,
  removeStoreFromLocalStorage,
  removeUserFromLocalStorage,
} from "../../utils/localStorage";
import customFetch from "../../utils/axios";
import { clearStoreThunk } from "./userThunk";
import { toast } from "sonner";
import { addUserToSessionStorage } from "../../utils/sessionStorage";

const initialState = {
  isLoading: false,
  status: null,
  user: getUserFromLocalStorage(),
};

// signup api calling

// signin/ login api calling
export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (user, thunkAPI) => {
    try {
      const resp = await customFetch.post("api/auth/login", user);
      return { data: resp.data, rememberMe: user.rememberMe };
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Invalid credentials");
      } else if (error.response && error.response.status === 10001) {
        toast.error("Username already existing");
      } else if (error.response && error.response.status === 10002) {
        toast.error("Email already existing");
      } else if (error.response && error.response.status === 10003) {
        toast.error("Company already existing");
      } else {
        toast.error("Error");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const signUpUser = createAsyncThunk(
  "user/signUpUser",
  async (user, thunkAPI) => {
    try {
      const resp = await customFetch.post("api/auth/register", user);
      return resp.data;
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Invalid credentials");
      } else if (error.response && error.response.status === 400) {
        toast.error("Email already existing");
      } else if (error.response && error.response.status === 10001) {
        toast.error("Username already existing");
      } else if (error.response && error.response.status === 10002) {
        toast.error("Email already existing");
      } else if (error.response && error.response.status === 10003) {
        toast.error("Company already existing");
      } else {
        toast.error(error.response.data.msg);
      }
      return thunkAPI.rejectWithValue("Error!");
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (email, thunkAPI) => {
    try {
      const resp = await customFetch.put("api/auth/forgetPassword", email);
      alert(resp.data);
      return resp.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Invalid Email or Mobile");
      } else {
        alert(error.response.data.msg);
      }
      return thunkAPI.rejectWithValue(error?.response?.data?.msg);
    }
  }
);

export const setPassword = createAsyncThunk(
  "user/setPassword",
  async ({ password, key }, thunkAPI) => {
    console.log(password, key);
    try {
      const resp = await customFetch.put(
        "api/auth/set-password",
        { password: password },
        {
          headers: { key: key },
        }
      );
      return resp;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Invalid Credentials");
      } else {
        alert(error.response.data.msg);
      }
      return thunkAPI.rejectWithValue(error?.response?.data?.msg);
    }
  }
);

export const VerifyOtp = createAsyncThunk(
  "user/verifyotp",
  async (otp, thunkAPI) => {
    try {
      const resp = await customFetch.put("api/auth/verify-account", otp);
      console.log(resp);
      return resp.data;
    } catch (error) {
      if (error.response) {
        toast.error("Invalid OTP!");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const VerifyLoginOtp = createAsyncThunk(
  "user/VerifyLoginOtp",
  async (data, thunkAPI) => {
    try {
      const resp = await customFetch.post("api/auth/verify-otp", data);
      console.log(resp);
      return { data: resp?.data, rememberMe: data?.rememberMe };
    } catch (error) {
      if (error.response) {
        toast.error("Invalid OTP!");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const generateOtp = createAsyncThunk(
  "user/generateOtp",
  async (data, thunkAPI) => {
    try {
      const resp = await customFetch.post("api/auth/generate-otp", data);
      console.log(resp);
      return resp.data;
    } catch (error) {
      if (error.response) {
        toast.warning("Error! Please try again after sometime.");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);
// signin/ login api calling

//Resfresh token post
export const refreshToken = createAsyncThunk(
  "user/refreshToken",
  async (_, thunkAPI) => {
    try {
      const { accessToken, refreshToken } = getUserFromLocalStorage();
      console.log("jwt : " + accessToken, "refresh : " + refreshToken);
      const resp = await customFetch.post("api/token/refresh", {
        refreshToken: refreshToken || accessToken,
      });

      return resp?.data;
    } catch (error) {
      if (error.response) {
        // toast.warning("Error! Please try again after sometime.");
      }
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const clearStore = createAsyncThunk("user/clearStore", clearStoreThunk);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logoutUser: (state, { payload }) => {
      state.user = null;
      removeUserFromLocalStorage();
      // removeStoreFromLocalStorage();
      if (payload) {
        setTimeout(() => {
          toast.success(payload);
        }, 2000);
      } else {
        setTimeout(() => {
          toast.warning("Unauthorization! Logging out...");
        }, 2000);
      }
    },
    removeStatus: (state) => {
      state.status = null;
    },
    setLoading: (state) => {
      state.isLoading = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        const user = payload?.data;
        state.isLoading = false;
        state.user = user;
        if (payload?.rememberMe) {
          addUserToLocalStorage(user);
        } else {
          addUserToSessionStorage(user);
        }
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(clearStore.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(signUpUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signUpUser.fulfilled, (state, { payload }) => {
        // edit
        state.status = 201;
        state.isLoading = false;
      })
      .addCase(signUpUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(setPassword.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(setPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setPassword.fulfilled, (state) => {
        state.status = 200;
        state.isLoading = false;
      })
      .addCase(VerifyOtp.fulfilled, (state) => {
        // edit
        state.status = 200;
        state.isLoading = true;
        alert("OTP Verified Successfully!");
      })
      .addCase(VerifyOtp.pending, (state) => {
        // edit
        state.isLoading = true;
      })
      .addCase(VerifyOtp.rejected, (state) => {
        // edit
        state.isLoading = false;
      })
      .addCase(VerifyLoginOtp.fulfilled, (state, { payload }) => {
        // edit
        const user = payload?.data;
        state.isLoading = false;
        state.user = user;
        toast.success("Login successfull!");
        if (payload?.rememberMe) {
          addUserToLocalStorage(user);
        } else {
          addUserToSessionStorage(user);
        }
      })
      .addCase(VerifyLoginOtp.pending, (state) => {
        // edit
        state.isLoading = true;
      })
      .addCase(VerifyLoginOtp.rejected, (state) => {
        // edit
        state.isLoading = false;
      })
      .addCase(generateOtp.fulfilled, (state, { payload }) => {
        // edit
        toast.success("OTP sent to your number.");
        state.isLoading = false;
      })
      .addCase(generateOtp.pending, (state) => {
        // edit
        state.isLoading = true;
      })
      .addCase(generateOtp.rejected, (state) => {
        // edit
        state.isLoading = false;
      })
      .addCase(refreshToken.fulfilled, (state, { payload }) => {
        // edit
        const token = payload?.jwtToken;
        const refreshToken = payload?.refreshToken;
        state.isLoading = false;
        const user = getUserFromLocalStorage();
        const newUser = {
          ...user,
          accessToken: token,
          refreshToken: refreshToken,
        };
        state.user = newUser;
        if (user?.rememberMe === false) {
          addUserToSessionStorage(newUser);
        } else {
          addUserToLocalStorage(newUser);
        }
      });
  },
});

export const { toggleSidebar, logoutUser, removeStatus, setLoading } =
  userSlice.actions;

export default userSlice.reducer;
